import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button'

const NotFoundPage = () => {

    return (
        <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <div style={{ textAlign: 'center' }}>
                    <h1 style={{ fontSize: '72px' }}>404</h1>
                    <label className="font-semibold block mb-2" style={{ fontSize: '24px' }}>Page Not Found</label>
                    <Link to="/">
                        <Button label="กลับหน้าแรก" icon="pi pi-home" className="p-button-lg p-button-danger p-button-plain p-button-text" />
                    </Link>
                </div>
            </div>
        </React.Fragment>
    )

}

export default NotFoundPage