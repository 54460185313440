import React from "react"
import { Dialog } from 'primereact/dialog'

const CustomDialog = (props) => {
    return (
        <React.Fragment>
            <Dialog
                header={props.Header}
                visible={props.Open}
                onHide={props.OnHide}
                maximizable={props.Maximizable}
                maximized={props.Maximized}
                // style={{ width: '50vw' }}
                style={{ width: props.Width + 'vw', height: props.Height + 'vh' }}
                footer={props.Footer}
            >
                {props.Content}
            </Dialog>
        </React.Fragment>
    )
}

export default CustomDialog