import React, { useEffect } from 'react'
import { useForm, Controller, get } from 'react-hook-form'
import { Button } from 'primereact/button'
//import Button from '@mui/material/Button';
import { InputText } from 'primereact/inputtext'
import { FileUpload } from 'primereact/fileupload'
import Swal from 'sweetalert2'
import { http, isEmpty, ErrorHandle } from '../../Utils'
import uuid from 'react-uuid';
import { useNavigate } from 'react-router-dom'
import Previews from '../Previews'
import Select from 'react-select'
import { G } from '@react-pdf/renderer'
const AddData = (props) => {

    const { setModalData, funcGetData, DataType, setDataType, DataID, setDataID } = props

    const { register, reset, handleSubmit, setValue, getValues, control, formState: { errors } } = useForm()

    const [ImageFile, setImageFile] = React.useState(null)
    const [ImageFileName, setImageFileName] = React.useState('')
    const [ChekcFile, setChekcFile] = React.useState(false)

    const [UserFileID, setUserFileID] = React.useState()
    const [Grop, setGrop] = React.useState()

    const _clearActionStatus = React.useRef(null)
    const _optionActionStatus = [
        { label: 'User', value: 1 },
        { label: 'Admin', value: 2 },
        { label: 'Super Admin', value: 3 },
    ]
    React.useEffect(() => {

        // const token = sessionStorage.getItem('sessionToken');
        // if (token === null) {
        //     Swal.fire({
        //         title: 'Error',
        //         text: 'กรุณาเข้าสู่ระบบ',
        //         icon: 'error',
        //         confirmButtonText: 'Ok'
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             Redirect('/404')
        //         }
        //     })

        // }
        // else {
        //     (async () => {
        //         if (!isEmpty(DataID) && DataType === 'Update') await getData()
        //     })()
        // }


        (async () => {
            if (!isEmpty(DataID) && DataType === 'Update') await getData()
        })()
    }, [DataID])

    const onSubmit = async (data) => {

        // if (isEmpty(data.UserFile)) return Swal.fire('แจ้งเตือน', 'กรุณาแนบไฟล์', 'warning')
        sessionStorage.getItem('RefID')
        setValue('RefID', sessionStorage.getItem('RefID'))
        if (isEmpty(data.UserName)) return Swal.fire('แจ้งเตือน', 'กรุณากรอก UserName', 'warning')
        // if (isEmpty(data.Password)) return Swal.fire('แจ้งเตือน', 'กรุณากรอก Password', 'warning')
        if (isEmpty(data.Email)) return Swal.fire('แจ้งเตือน', 'กรุณากรอก Email', 'warning')

        if (sessionStorage.getItem('RefID') === null) {
            setValue('RefID', uuid("00000000-0000-0000-0000-000000000000"))
        }

        Swal.fire({
            icon: 'question',
            title: `ยืนยันการ${DataType === 'Save' ? 'บันทึก' : 'แก้ไข'}`,
            text: `คุณต้องการ${DataType === 'Save' ? 'บันทึก' : 'แก้ไข'}ข้อมูลใช่หรือไม่`,
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            confirmButtonColor: '#22C55E',
            cancelButtonColor: '#EF4444',
        }).then(async (result) => {

            if (!result.isConfirmed) return

            try {

                const _res = await http.post(DataType === 'Save' ? `/GetStock/Save` : `/GetStock/Update/${DataID}`, { ...data, RefID: getValues('RefID') })

                if (_res.status === 200 && _res.data.status) {

                    Swal.fire({ icon: 'success', title: 'สำเร็จ', text: _res.data.msg })
                    clearData()
                    sessionStorage.removeItem('RefID')
                    await funcGetData()
                }

            } catch (err) {
                ErrorHandle(err)
            }
        })
    }
    const [IsLoading, setIsLoading] = React.useState(false)
    const getData = React.useCallback(async () => {

        if (isEmpty(DataID)) return

        try {

            const _res = await http.post(`/GetStock/FindById/${DataID}`)
            if (_res.status === 200 && _res.data.status) {
                setValue('UserName', _res.data.data.userName)
                setValue('Email', _res.data.data.email)
            }

        } catch (err) {
            console.log(err)
            ErrorHandle(err)
        }
    }, [DataID])
    const getFile = async (FileID) => {
        if (isEmpty(FileID)) return
        try {
            const _res = await http.get(`/File/View/${FileID}`, { responseType: 'blob' })
            if (_res.status === 200) {
                setImageFile(_res.data)
                setChekcFile(true)

            }
        } catch (err) {
            setChekcFile(false)
        }
    }
    //File Upload
    const onUploadFile = async () => {
        Swal.fire({
            icon: 'info',
            title: 'ยืนยันการอัพโหลด',
            text: 'คุณต้องการอัพโหลดใช่หรือไม่',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            confirmButtonColor: '#22C55E',
            cancelButtonColor: '#EF4444',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const _FormData = new FormData()
                    _FormData.append('file', ImageFile)
                    _FormData.append('RefType', 'User')
                    sessionStorage.setItem('RefID', uuid())
                    _FormData.append('RefID', sessionStorage.getItem('RefID'))
                    const _res = await http.post('/File/Upload', _FormData, { headers: { 'Content-Type': 'multipart/form-data' } })
                    if (_res.status === 200 && _res.data.status) {
                        Swal.fire({ icon: 'success', title: 'สำเร็จ', text: 'อัพโหลดไฟล์สำเร็จ' })
                        setChekcFile(true)
                    }
                } catch (err) { ErrorHandle(err) }
            }
        })
    }

  
    const deleteFile = async () => {
        Swal.fire({
            icon: 'info',
            title: 'ยืนยันการลบไฟล์',
            text: 'คุณต้องการลบไฟล์ใช่หรือไม่',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            confirmButtonColor: '#22C55E',
            cancelButtonColor: '#EF4444',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const _res = await http.delete(`/File/Delete?FileID=${getValues('UserFile')}`)

                    if (_res.status === 200 && _res.data.status) {
                        Swal.fire({ icon: 'success', title: 'สำเร็จ', text: _res.data.msg })
                        setImageFile('')
                        setImageFileName('')
                        setValue('UserFile', '')
                        setChekcFile(false)
                    }
                } catch (err) { ErrorHandle(err) }
            }
        })
    }
    const onBasicUpload = (e) => {

        if (isEmpty(e.files) || e.files.length === 0) return

        if (e.files[0].size > 1000000) return Swal.fire({ icon: 'error', title: 'เกิดข้อผิดพลาด', text: 'ขนาดไฟล์ใหญ่เกินไป' })

        for (let i = 0; i < e.files.length; i++) {
            setImageFile(e.files[i])
            setImageFileName(e.files[i].name)
        }
    }

    const clearData = () => {
        setModalData(false)
        reset()
        setImageFile('')
        setImageFileName('')
        setDataID('')
        setChekcFile(false)
        // setCheckFileNews(false)
        setDataType('Save')
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-column-reverse card-container m-3  ">
                    <div className="flex align-items-center justify-content-center h-4rem m-2">
                        <div className='grid'>
                            <div className="col-12">
                                <label className="font-semibold block mb-2 text-center" style={{ lineHeight: '1.5' }}>เพิ่มรูปภาพ <span className="text-red-500">*</span></label>
                                <FileUpload
                                    mode="basic"
                                    name="demo"
                                    onSelect={onBasicUpload}
                                    maxFileSize={1}
                                    auto
                                    disabled={ChekcFile ? true : false}
                                    chooseOptions={{ label: 'เลือกไฟล์', icon: 'pi pi-fw pi-file', className: "p-button-sm " }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {
                    ImageFile &&
                    <React.Fragment>
                        <div className="flex justify-content-center flex-wrap card-container">
                            <div className="flex align-items-center justify-content-center m-2">
                                {/* <Image src={URL.createObjectURL(ImageFile)} alt="Image" width="200" height="200" preview /> */}
                                <Previews Files={URL.createObjectURL(ImageFile)} Width={200} Height={200} />

                            </div>
                        </div>
                        <div className="flex justify-content-center flex-wrap card-container">
                            <div className="flex align-items-center justify-content-center m-2">
                                <p className="m-0" style={{ lineHeight: '1.5' }}>{ImageFileName}</p>
                            </div>
                        </div>
                        <div className="flex justify-content-center flex-wrap card-container">
                            <div className="flex align-items-center justify-content-center m-2">
                                {
                                    !ChekcFile && <Button type="button" label="อัพโหลด" icon="pi pi-upload" className="p-button-success p-button-sm mt-1 mr-1 " onClick={onUploadFile} />
                                }
                                <Button type="button" label={ChekcFile ? "ลบไฟล์" : "ยกเลิก"} icon="pi pi-trash" className="p-button-danger p-button-sm mt-1 mr-1 " onClick={() => {
                                    if (ChekcFile) return deleteFile()
                                    setImageFile('')
                                    setImageFileName('')
                                }} />
                            </div>
                        </div>
                    </React.Fragment>
                }
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6 mt-2">

                        <div className="grid">
                            <div className="col-12">
                                <label className="font-semibold block mb-2">UserName <span className="text-red-500">*</span></label>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-user"></i>
                                    </span>
                                    {DataType === 'Update' &&
                                        <InputText

                                            {...register("UserName", { required: true })}
                                            disabled={true}
                                        />
                                    }
                                    {DataType === 'Save' &&
                                        <InputText
                                            {...register("UserName", { required: true })}
                                        />
                                    }
                                </div>
                                {errors.userName && <span className='text-red-500'>Please Add UserName</span>}
                            </div>
                            <div className="col-12">
                                <label className="font-semibold block mb-2">Password <span className="text-red-500">*</span></label>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-user"></i>
                                    </span>
                                    {
                                        DataType === 'Update' &&
                                        <InputText type='password'
                                            rows={5}
                                            cols={30}
                                            {...register("Password", { required: false })}
                                        />
                                    }

                                    {
                                        DataType === 'Save' &&
                                        <InputText type='password'
                                            rows={5}
                                            cols={30}
                                            {...register("Password", { required: true })}
                                        />
                                    }

                                </div>
                                {errors.password && <span className='text-red-500'>Please Add Password</span>}
                            </div>
                            <div className="col-12">
                                <label className="font-semibold block mb-2">Email <span className="text-red-500">*</span></label>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-book"></i>
                                    </span>
                                    <InputText type='email'
                                        rows={5}
                                        cols={30}
                                        {...register("Email", { required: true })}
                                    />
                                </div>

                                {errors.email && <span className='text-red-500'>Please Add Email</span>}
                            </div>

                            {DataType === 'Update' &&
                                <div className="field col-6">
                                    <label className="font-semibold block mb-2">Add Grop<span className="text-red-500">*</span></label>
                                    <Select

                                        options={_optionActionStatus}
                                        ref={_clearActionStatus}
                                        placeholder={Grop === 1 ? 'User' : Grop === 2 ? 'Admin' : Grop === 3 ? 'Super Admin' : 'Select Level'}
                                        className="w-full"
                                        isClearable
                                        isSearchable
                                        {...register("grop", { required: true })}
                                        onChange={(e) => {
                                            setValue('grop', e.value)
                                        }}
                                    />
                                    {errors.grop && <span className='text-red-500'>Please Add Grop</span>}
                                </div>
                            }

                        </div>
                    </div>
                </div>
                <div className="flex justify-content-center flex-wrap card-container">
                    <div className="flex align-items-center justify-content-center m-2">
                        <Button icon="pi pi-pencil" aria-label="Filter" type="submit" label="บันทึก" className="p-button-raised p-button-success p-button-sm mt-3 mr-2   "
                        /> &nbsp;
                        <Button icon="pi pi-times" aria-label="Filter" type="button" label="ยกเลิก" className="p-button-raised p-button-danger p-button-sm mt-3 mr-2  btn btn-primary btn-lg float-end" onClick={() => {
                            // if (!ChekcFile && DataType === 'Update') return Swal.fire({ icon: 'warning', title: 'แจ้งเตือน', text: 'ไฟล์แนบต้องไม่เป็นค่าว่าง' })
                            clearData()
                        }} />
                    </div>
                </div>
            </form>
        </React.Fragment>
    )
}
export default AddData