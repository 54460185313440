 
import './App.css';
import React  from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'
import Login from './Components/Login';
import NotFound from './Pages/404'
import ListData  from './Components/List';

const Layout = React.lazy(() => import('./Components/Layout'))

const App = () => {

    const [isLogin, setIsLogin] = React.useState(false)


    return (
        <React.Suspense fallback={<></>}>
            <Routes>
                {/* <Route path="/" element={<Login />} /> */}
                <Route path="/" element={<Layout />} >
                    <Route path="/" element={<ListData   />} />
                    {/* <Route path="/GetStock" element={<ListData   />} /> */}
                    <Route path="/Login" element={<Login />} />
                </Route>
                <Route path='/404' element={<NotFound />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </React.Suspense>

    )
}
export default App