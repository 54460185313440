import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './App.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import 'primereact/resources/themes/lara-light-indigo/theme.css'  //theme
import "primereact/resources/primereact.min.css"                 //core css
import "primeicons/primeicons.css"                               //icons
import 'primeflex/primeflex.css'
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import 'primeicons/primeicons.css'

import 'aos/dist/aos.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode> 
);


reportWebVitals();
