import React from 'react'
import { Backdrop, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    transform: "translateZ(0)",
    backdrop: {
        zIndex: theme.zIndex.drawer + 99999999,
        color: '#fff',
    },
}))

const Loading = ({ isLoading = false }) => {

    const classes = useStyles()

    return (
        <React.Fragment>
            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit" size={70} disableShrink={true} />
            </Backdrop>
        </React.Fragment>
    )
}

export default Loading