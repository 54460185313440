import React from "react";
import { Routes, Route, Navigate } from 'react-router-dom'
import { Button } from 'primereact/button';
import Container from 'react-bootstrap/Container';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBInput,
}
    from 'mdb-react-ui-kit';
import Swal from 'sweetalert2'
import { http, isEmpty, ErrorHandle } from '../../Utils'
import { useForm } from "react-hook-form";
import { PrimeIcons } from 'primereact/api';

const Login = () => {
    const { register, reset, handleSubmit, setValue, getValues, control, formState: { errors } } = useForm()
    const onSubmit = async (data) => {
        try {
            const response = await http.post('login',{...data})
            if (response.status === 200 && response.data.status === 'success') {

                Swal.fire({
                    title: 'Success',
                    text: response.data.message,
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                }).then((timer) => {
                    if (timer.dismiss === Swal.DismissReason.timer) {

                       sessionStorage.setItem('userName', response.data.data.userName)
                       localStorage.setItem('Img', response.data.file)
                       window.location.href = "/GetStock"
                    }
                })

            }
            else {
                Swal.fire({
                    title: 'Error',
                    text: response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }

        } catch (error) {
            ErrorHandle(error)
        }
    }
    return (

        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Container>
                    <MDBContainer fluid className="p-3 my-5 h-custom">
                        <MDBRow>
                            <MDBCol md='6' className='mx-auto'>
                                <div className="d-flex flex-row align-items-center justify-content-center mt-2">
                                    {
                                         localStorage.getItem("Img") === null ?
                                         <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp" className="img-fluid" alt="Sample image" />
                                         :
                                         <img src={localStorage.getItem("Img")} alt="Admin" className="rounded-circle" width="200" />
                                    }
                                </div>
                                <p>UserName</p>
                                <MDBInput wrapperClass='mb-4'  type='text' size="lg"
                                    {...register("username", { required: true })}
                                />

                                <p>Password</p>
                                <MDBInput wrapperClass='mb-4' type='password' size="lg"
                                    {...register("password", { required: true })}
                                />
                                <div className="d-flex justify-content-between mb-4">
                                </div>

                                <div className='text-center text-md-start mt-4 pt-2'>
                                    <Button variant="contained" type="submit"  size="large"  >Login</Button>
                                    <p className="small fw-bold mt-2 pt-1 mb-2">Don't have an account? <a href="#!" className="link-danger">Register</a></p>
                                </div>

                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </Container>

            </form>

        </React.Fragment>

    )

}
export default  Login
