import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom'
import { Card } from 'primereact/card'
import { http, isEmpty, DateThai, ErrorHandle, truncateText } from '../../Utils'
import { Image } from 'primereact/image'
import Swal from 'sweetalert2'
import DialogModal from '../Dialog'
import DataTable from '../DataTable'
import SearhFilter from '../DataTable/SearhFilter'
import AddNews from './AddData'
import Loading from '../Loading'
import { Container } from '@material-ui/core'
import { useForm } from "react-hook-form";
import { TreeSelect } from 'primereact/treeselect';


const List = (props) => {

    const { register, reset, handleSubmit, setValue, getValues, control, formState: { errors } } = useForm()
    const Redirect = useNavigate()
    const _MenuID = 8

    const [Search, setSearch] = React.useState('')
    const [IsLoading, setIsLoading] = React.useState(false)
    const [Page, setPage] = React.useState(1)
    const [Per_Page, setPerPage] = React.useState(10)
    const [TotalRecords, setTotalRecords] = React.useState(0)
    const [Data, setData] = React.useState([])
    const [DataID, setDataID] = React.useState('')
    const [DataType, setDataType] = React.useState('Save')
    const [ModalData, setModalData] = React.useState(false)
    const [ModalUnlockData, setModalUnlockData] = React.useState(false)
    const [IsLoadingPage, setIsLoadingPage] = React.useState(false)
    const [tokenUser, settokenUser] = React.useState('')
    const [Aciton, setAction] = React.useState('')

    React.useEffect(() => {
        (async () => await getData())()
        document.title = 'Taila Qr Code Web'
    }, [props.title]);

    const data = [
        { label: 'มีในคลัง', Action: 1 },
        { label: 'ขาย', Action: 2 },
        { label: 'เสีย', Action: 3 },
        { label: 'ส่งคืน', Action: 4 },
        { label: 'เดโม่', Action: 5 },
        { label: 'เครม', Action: 6 },
        { label: 'เบิกของออก', Action: 7 },

    ]

    const [select, setSelect] = useState(0)
    React.useEffect(() => {
        (async () => await getData())()

    }, [select])
    const getData = async (page = 1, search = '') => {
        try {
            setIsLoading(true)
            if (select == 0) {
                const _res = await http.post(`/api/GetStock?page=${page}&per_page=${Per_Page}${!isEmpty(search) ? `&search=${search}` : ''}`)
                if (_res.status === 200) {
                    setIsLoading(false)
                    setData(_res.data.data)
                    setTotalRecords(_res.data.total)
                }
            }
            else {
                const _res = await http.post(`/api/GetStock/GetAction?page=${page}&per_page=${Per_Page}&select=${select - 1}${!isEmpty(search) ? `&search=${search}` : ''}`)
                console.log(_res)
                if (_res.status === 200) {
                    setIsLoading(false)
                    setData(_res.data.data)
                    setTotalRecords(_res.data.total)
                }
            }
        } catch (err) {
            ErrorHandle(err)
        }
    }
    const handleSort = async ({ sortField }, sortDirection) => {
        try {
            setIsLoading(true)
            if (select == 0) {
                const _res = await http.post(`/api/GetStock?page=${Page}&per_page=${Per_Page}&sort=${sortField}&order=${sortDirection}${!isEmpty(Search) ? `&search=${Search}` : ''}`)
                if (_res.status === 200) {
                    setTotalRecords(_res.data.total)
                    setData(_res.data.data)
                    setIsLoading(false)
                }
            }
           else {
                const _res = await http.post(`/api/GetStock/GetAction?page=${Page}&per_page=${Per_Page}&select=${select - 1}&sort=${sortField}&order=${sortDirection}${!isEmpty(Search) ? `&search=${Search}` : ''}`)
                if (_res.status === 200) {
                    setIsLoading(false)
                    setData(_res.data.data)
                    setTotalRecords(_res.data.total)
                }
            }

        } catch (err) { ErrorHandle(err) }
    }
    const handleSearch = async (value) => {
        await getData(1, value)
        setSearch(value)
        setPage(1)
    }

    const handlePerRowsChange = async (newPage, page) => {
        try {
            setIsLoading(true)
            if (select == 0) {
            const _res = await http.post(`/api/GetStock?page=${page}&per_page=${newPage}${!isEmpty(Search) ? `&search=${Search}` : ''}`)
            if (_res.status === 200) {
                setIsLoading(false)
                setData(_res.data.data)
                setTotalRecords(_res.data.total)
                setPerPage(newPage)
            }
            else {
                const _res = await http.post(`/api/GetAction?page=${page}&per_page=${newPage}&select=${select - 1}${!isEmpty(Search) ? `&search=${Search}` : ''}`)
                if (_res.status === 200) {
                    setIsLoading(false)
                    setData(_res.data.data)
                    setTotalRecords(_res.data.total)
                    setPerPage(newPage)
                }
            }
        }
        } catch (err) { ErrorHandle(err) }
    }
    const handlePageChange = async (page) => {
        await getData(page)
        setPage(page)
    }
    const _DataTable = React.useMemo(() => Data, [Data])
    const _columns = React.useMemo(() => [

        {
            name: '#',
            cell: (row, index) => index + 1,
            sortable: false,
            width: '5%'
        },
        // {
        //     name: 'รูปภาพ',
        //     cell: (row) => {
        //         //  return <Image src={isEmpty(row.stockId) || isEmpty(row.fileUpload) || !row.checkFile ? window.location.origin + '/images/empty.png' : row.fileUpload} alt="Image" width="40" preview />
        //         return <Image src={isEmpty(row.stockId) || isEmpty(row.fileUpload) ? window.location.origin + '/images/empty.png' : row.fileUpload} alt="Image" width="40" preview />
        //     },
        //     center: true,
        //     sortable: false,
        // },
        {
            name: 'QR Code',
            selector: (row) => row.qrCode,
            sortField: 'qrCode',
            sortable: true,

        },
        {
            name: 'Model',
            selector: (row) => row.model,
            sortField: 'model',
            sortable: true,

        },
        {
            name: 'Type',
            selector: (row) => row.refType,
            sortField: 'refType',
            sortable: true,
        },
        {
            name: 'สถานะ',
            selector: (row) => (row.actionStatus === 0 ?
                <a style={{ color: 'blue' }}> มีในคลัง </a>
                : row.actionStatus === 1 ?
                    <a style={{ color: 'green' }}> ขายแล้ว </a>
                    : row.actionStatus === 2 ?
                        <a style={{ color: 'red' }}> เสีย </a>
                        : row.actionStatus === 3 ?
                            <a style={{ color: 'orange' }}> ส่งคืน </a>
                            : row.actionStatus === 4 ?
                                <a style={{ color: 'purple' }}> เดโม่ </a>
                                : row.actionStatus === 5 ?
                                    <a style={{ color: 'pink' }}> เครม </a>
                                    : row.actionStatus === 6 ?
                                        <a style={{ color: 'brown' }}> เบิกของออก </a>
                                        : <></>

            ),
            sortField: 'actionStatus',
            sortable: true,

        },
        {
            name: 'ผู้สร้าง',
            selector: (row) => row.createBy,
            sortField: 'createBy',
            sortable: true,

        },
        {
            name: 'วันที่สร้าง',
            selector: (row) => DateThai(row.createDate),
            sortField: 'datetime',
            sortable: true,
        },
        {
            name: 'วันที่แก้ไข',
            selector: (row) => DateThai(row.lastUpdate),
            sortField: 'datetime',
            sortable: true,
        },
        {
            name: 'ACTION',
            cell: (row) => {
                return (
                    <React.Fragment>

                        {/* <i className="pi pi-wrench" style={{ fontSize: '1.2em', cursor: 'pointer', color: 'blue', margin: 20 }} onClick={() => {
                            setDataID(row.userId)
                            setDataType('Update')
                            setModalData(!ModalData)
                        }} />

                        <i className="pi pi-trash" style={{ fontSize: '1.2em', cursor: 'pointer', color: 'red' }} onClick={() => {
                            Swal.fire({
                                title: 'คุณต้องการลบข้อมูลใช่หรือไม่?',
                                text: "ข้อมูลที่ถูกลบจะถูกย้ายไปยัง Unlock User ต้องการลบ " + row.qrCode + " หรือไม่ ?",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                            }).then(async (result) => {
                                if (result.isConfirmed) {
                                    try {
                                        setIsLoadingPage(true)
                                        const _res = await http.post(`api/GetStock/Delete/${row.stockId}`)
                                        if (_res.status === 200) {
                                            setIsLoadingPage(false)
                                            Swal.fire(
                                                'ลบข้อมูลสำเร็จ!',
                                                'ข้อมูลของคุ��ถูกลบเรียบร้อยแล้ว StockId' + row.stockId,
                                                'success'
                                            )
                                            await getData()
                                        }
                                    } catch (err) {
                                        setIsLoadingPage(false)
                                        ErrorHandle(err)
                                    }
                                }
                            }
                            )
                        }} /> */}

                    </React.Fragment>
                )
            },
            sortField: 'CreateDate',
            sortable: true,
        },
    ], [_DataTable])
    return (
        <React.Fragment>
            <Loading isLoading={IsLoadingPage} />
            <DialogModal
                Header={DataType === 'Save' ? 'เพิ่มข้อมูล' : 'แก้ไขข้อมูล'}
                style={{ lineHeight: '1.5' }}
                Open={ModalData}
                // Footer={<Button className='p-button-danger' onClick={() => setModalShowRight(false)}>Close</Button>}
                OnHide={() => setModalData(!ModalData)}
                Width={50}
                Content={
                    <AddNews
                        funcGetData={getData}
                        setModalData={setModalData}
                        DataID={DataID}
                        setDataID={setDataID}
                        DataType={DataType}
                        setDataType={setDataType}
                    />
                }
            />

            <Container style={{ marginTop: '5rem', }}>
                <Card>

                    <div className="flex justify-between items-center mb-3">
                        <select name="select" id="select" className="p-inputtext p-component p-filled" style={{ width: '200px' }}
                            onChange={(e) => setSelect(e.target.value)}

                        >
                            <option value="">เลือกประเภท</option>
                            {data.map((item, index) => (
                                <option key={index} value={item.Action}>{item.label}</option>

                            ))}
                        </select>
                    </div>
                    <DataTable
                        title={
                            <div className="grid">

                                <div className="col-12 md:col-6 lg:col-3 p-1">
                                    <span className="mr-2"> {props.title} </span>
                                    {/* <Button variant="contained" onClick={() => { setModalData(!ModalData); setDataType('Save') }}>เพิ่มข้อมูล</Button> */}

                                </div>
                            </div>
                        }
                        defaultSortFieldId={6}
                        columns={_columns}
                        data={_DataTable}
                        progressPending={IsLoading}
                        paginationTotalRows={TotalRecords}
                        paginationServer
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        sortServer
                        onSort={handleSort}
                        subHeader
                        subHeaderComponent={<SearhFilter onFilter={handleSearch} />}
                    />
                    <p>จำนวนทั้งหมด {TotalRecords}</p>
                </Card>

            </Container>
        </React.Fragment>
    )
}

export default List

