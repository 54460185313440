import React from 'react'
import { Image } from 'primereact/image'
import { CheckVideoType, isEmpty } from '../../Utils'


import './style.css'

const Previews = (props) => {
    const { FilesType, Files, FilesName, setDuration, Width, Height } = props
    const handleContextMenu = (e) => e.preventDefault()
    React.useEffect(() => {
        document.addEventListener("contextmenu", handleContextMenu)
        return () => document.removeEventListener("contextmenu", handleContextMenu)

    }, [FilesType])

    return (
        <React.Fragment>
     
        </React.Fragment>
    )
}
export default  Previews